import React from 'react';
import { PageProps } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { useTableOrMobile } from '../hooks';
import { cannonStyled, useCannonStyletron } from '../theme';
import Locally from '../components/sections/Locally';
import analyticsCustomEvent from '../atoms/js/analyticsCustomEvent';

// STYLED COMPONENTS

// const BreadCrumbsContainer = cannonStyled(
//   'div',
//   (props: { isMobile: boolean }) => ({
//     position: 'absolute',
//     top: props.isMobile ? '10px' : '30px',
//     left: props.isMobile ? '16px' : '10px',
//     width: '100%',
//     textAlign: 'left',
//   })
// );

const ResponsiveContainer = cannonStyled('div', {
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '100vw',
});

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '100vw',
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

// COMPONENT
const WhereToBuy: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();

  React.useEffect(() => {
    window.location.href = '/';
  }, []);

  // STATE

  // HANDLERS & FUNCTIONS

  // EFFECTS & CALLBACKS

  // RENDER

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <ResponsiveContainer>
          {analyticsCustomEvent('where_to_buy')}
          <Locally />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default WhereToBuy;
